export const formatDate3 = (date) => {
    const now = new Date(date);
    let month = (now.getMonth() + 1);
    let day = now.getDate();
    if (month < 10)
        month = "0" + month;
    if (day < 10)
        day = "0" + day;
    const today = now.getFullYear() + '-' + month + '-' + day + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
    return today;
}