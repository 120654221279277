import axios from 'axios';

const BASE_URL = "https://pay.slifetracker.com/deriv_ws/";

const httpRequest = (url, data, congif) => {
    let options = {
        headers: {
            'Content-Type' : 'application/json',
            'D-TOKEN' : congif.token,
            'D-APPID' : congif.appId
        }
    };
    return axios.post(
        `${BASE_URL}${url}`,
        data,
        options
    );
}

export const httpGetRequest = (url, congif) => {
    let options = {
        headers: {
            'Content-Type' : 'application/json',
            'D-Token' : congif.token,
            'D-Appid' : congif.appId
        }
    };
    return axios.get(
        `${BASE_URL}${url}`,
        options
    );
}

export default httpRequest;