import { formatDate3 } from "./../function";

const SmsR = (props) => {

  return (
    <tr>
      <td>{props.dt.passerelle.name}</td>
      <td>{formatDate3(props.dt.createdAt)}</td>
      <td>{props.dt.phone}</td>
      <td>{props.dt.message}</td>
    </tr>
  );
}

export default SmsR;