import React from 'react';
import ReactDOM from 'react-dom';
import { Suspense } from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home';
import Accueil from './Accueil';
import SmsReceive from './SmsReceive';
import RetraitAndcorp from './RetraitAndcorp';

//const Home = lazy(() => import('./App'));

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<div className="container">Loading...</div>}>
        <Routes>
          <Route path="/:token/:appId" element={<App />}>
            <Route path='home' element={<Home />} />
            <Route path='smsReceive' element={<SmsReceive />} />
            <Route path='retraitAndcorp' element={<RetraitAndcorp />} />
          </Route>
          <Route path="" element={<Accueil />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
