import logo from './logo.svg';
import './App.css';
import { Outlet, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Nav from './layout/Navbar';
import { httpGetRequest } from './HttpRequestService';
import { useState } from 'react';

function App() {
  let params = useParams();

  const token = params.token;
  const appId = params.appId;
  const validToken = token.trim() !== "" ? true : false;
  const validAppId = appId.trim() !== "" ? true : false;

  return (
    <Container>
      {(validToken && validAppId) && <>
        {/* <Nav /> */}
        <div className="App">
          <Outlet context={[token, appId]} />
        </div>
        <Row>
          <Col className="text-center mt-5" style={{fontSize: '10px'}}>
          SLifePay - App Deriv <br />
            © 2023, Powered by{" "}
            <a href="https://slifetracker.mg/" target="_blank" rel="noreferrer">
              SLife Tracker
            </a>{" "}
            , Developed with <i class="fa fa-heart text-danger"></i>{" "} by {" "}
            <a href="https://www.facebook.com/trivomanana/" target="_blank" rel="noreferrer">
              TOKINIAINA T.R
            </a>
          </Col>
        </Row>
      </>}
    </Container>
  );
}

export default App;
